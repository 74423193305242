import emoji from "react-easy-emoji";

import laborit from "./assets/img/icons/common/laborit.jpeg";
import phx from "./assets/img/icons/common/phxlogo.jpg";
import ideal from "./assets/img/icons/common/ideal.png";
import locaweb from "./assets/img/icons/common/locaweb.jpeg";

export const greetings = {
	name: "Danilo Righetto",
	title: "Hi all, I'm Danilo",
	description:
		"A passionate Full Stack Web Developer having an experience of building Web applications with JavaScript / Reactjs / Nodejs / Python / Django / PHP and some other cool libraries.",
	resumeLink: "https://www.linkedin.com/in/danilo-righetto/",
};

export const openSource = {
	githubUserName: "danilorighetto",
};

export const contact = {};

export const socialLinks = {
	facebook: "https://www.facebook.com/danilo.righeto",
	instagram: "https://www.instagram.com/danilo.righetto/",
	twitter: "https://twitter.com/danilorighetto",
	github: "https://github.com/danilo-righetto",
	linkedin: "https://www.linkedin.com/in/danilo-righetto/",
};

export const skillsSection = {
	title: "What I do",
	subTitle:
		"FULL STACK DEVELOPER WHO WANTS TO EXPLORE EVERY TECH STACK",
	skills: [
		emoji(
			"⚡ Develop highly interactive Backend / User Interfaces for your web applications"
		),
		emoji(
			"⚡ Progressive Web Applications ( PWA ) in normal and SPA Stacks"
		),
		emoji(
			"⚡ Integration of third party services such as Firebase/ AWS / Digital Ocean"
		),
	],

	softwareSkills: [
		{
			skillName: "html-5",
			fontAwesomeClassname: "vscode-icons:file-type-html",
		},
		{
			skillName: "css3",
			fontAwesomeClassname: "vscode-icons:file-type-css",
		},
		{
			skillName: "sass",
			fontAwesomeClassname: "logos:sass",
		},
		{
			skillName: "JavaScript",
			fontAwesomeClassname: "logos:javascript",
		},
		{
			skillName: "TypeScript",
			fontAwesomeClassname: "logos:typescript-icon",
		},
		{
			skillName: "reactjs",
			fontAwesomeClassname: "vscode-icons:file-type-reactjs",
		},
		{
			skillName: "nodejs",
			fontAwesomeClassname: "logos:nodejs-icon",
		},
		{
			skillName: "flutter",
			fontAwesomeClassname: "logos:flutter",
		},
		{
			skillName: "swift",
			fontAwesomeClassname: "vscode-icons:file-type-swift",
		},
		{
			skillName: "npm",
			fontAwesomeClassname: "vscode-icons:file-type-npm",
		},
		{
			skillName: "sql-database",
			fontAwesomeClassname: "vscode-icons:file-type-sql",
		},
		{
			skillName: "mongoDB",
			fontAwesomeClassname: "vscode-icons:file-type-mongo",
		},
		{
			skillName: "aws",
			fontAwesomeClassname: "logos:aws",
		},
		{
			skillName: "firebase",
			fontAwesomeClassname: "logos:firebase",
		},
		{
			skillName: "python",
			fontAwesomeClassname: "logos:python",
		},
		{
			skillName: "git",
			fontAwesomeClassname: "logos:git-icon",
		},
		{
			skillName: "docker",
			fontAwesomeClassname: "logos:docker-icon",
		},
	],
};

export const SkillBars = [
	{
		Stack: "PHP/Laravel",
		progressPercentage: "90",
	},
	{
		Stack: "Javascript/Nodejs",
		progressPercentage: "75",
	},
	{
		Stack: "Python/Django",
		progressPercentage: "50",
	},
	{
		Stack: "Frontend/Design",
		progressPercentage: "65",
	},
];

export const educationInfo = [
	{
		schoolName: "Faculdade Impacta de Tecnologia",
		subHeader: "Analysis and systems development",
		duration: "January 2013 - December 2015",
		desc: "I participated in the Integrated Week where several people from the IT area give a lecture on experience in the work career.",
		descBullets: [
			"Programming language",
			"Programming logic",
			"Aplicated math",
			"Web development",
			"Software Engineering",
			"Development of APIs and Microservices",
			"Business Agility",
			"cybersecurity",
		],
	},
];

export const experience = [
	{
		role: "Software Engineer",
		company: "Laborit",
		companylogo: laborit,
		date: "May 2021 – Present",
		desc: "Some of my main assignments include:",
		descBullets: [
			"Implement, propose and improve software project architectures (backend) according to the specifications and needs of customers and projects.",
			"Creation of pull requests for monitoring and continuous improvement of quality and safety standards.",
			"Work with projects within the AWS framework.",
			"Technology stacks: PHP 7, PHPUnit, NodeJs, MySQL, Mongo, Redis, Docker, AWS (RDS, S3, SES, Api Gateway, Cloudfront, Cloudformation, SSO, CloudWatch, EC2), Sentry, Firebase, WordPress.",
		],
	},
	{
		role: "System Analyst",
		company: "PHX CARGO",
		companylogo: phx,
		date: "May 2020 – May 2021",
		desc: "Some of my main assignments include:",
		descBullets: [
			"Implement new cargo management systems in the express cargo import and export segment (courier).",
			"Maintain the quality of the products that are being developed and made available to our customers, always seeking excellence.",
			"Assist in the organization of the IT area creating new processes and procedures based on the ITIL framework.",
			"Manage continuous integration environments (AWS), as well as DEPLOY windows of systems based on: laravel, vuejs, angular and .net.",
		],
	},
	{
		role: "PHP Developer",
		company: "Ideal",
		companylogo: ideal,
		date: "September 2019 – April 2020",
		desc: "Some of my main assignments include:",
		descBullets: [
			"Develop features for systems that work with foreign trade, thus bringing more and more value to the company and its customers.",
			'In addition to working with different types of "design patterns" and unit tests with a development team very focused and integrated in the main areas of the company.',
		],

	},
	{
		role: "System Analyst",
		company: "Locaweb",
		companylogo: locaweb,
		date: "June 2018 – September 2019",
		desc: "Some of my main assignments include:",
		descBullets: [
			"Create Test Cases",
			"Estimate Function Points",
			"Manage Nonconformities",
			"Implement the Solution",
			"Get feedback from customers",
			"Design the Solution",
			"Design the Data Model",
			"Perform Product Transfer",
			"Work on creating features for: recommendation generation systems - capturing customer information in real time and saving in non-relational databases with Nodejs - customer management and monitoring dashboard (using Vuejs in frontend).",
		],

	},
];

export const projects = [
	{
		name: "Udemy Python Course",
		desc: "Learning to work with Python from scratch to advanced.",
		github: "https://github.com/danilo-righetto/udemy-python-course",
	},
	{
		name: "Learn Typescript",
		desc: "Understanding how Typescript and its applications work.",
		github: "https://github.com/danilo-righetto/learn-typescript/tree/master/backend",
	},
	// {
	// 	name: "lorem ipsum",
	// 	desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
	// 	github: "https://github.com/1hanzla100",
	// 	link: "https://example.com",
	// },
];

export const feedbacks = [
	{
		name: "Hassan Mehmood",
		feedback:
			"We have been very happy with our new website! It looks professional and very easy to navigate. Our experience with the customer service at Danilo Righetto has been great. They handle things very efficiently and are available for any questions we have. They also keep us updated on daily reports so we know how our site is doing. I would recommend that u have choose hanzla web developer services for u.",
	},
	{
		name: "Maheen Altaf",
		feedback:
			"the website is very nice, everything was perfectly made as i need. it just loads in moments, without giving u the chance to wait. the quality is also very amazing. i am using it without any problem. great job",
	},
];
